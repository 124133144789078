import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Careers() {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const toggleMenu = () => {
    //console.log(isMenuOpen)
    setIsMenuOpen(!isMenuOpen);
  }
  const closeMenu = () => {
    setIsMenuOpen(false);
  }
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        <section className='pt-6 pb-20 bg-gray-900 overflow-hidden'>
          <div className='container mx-auto px-4'>
          <div className='mb-12'>
              <div className='flex items-center justify-between px-6 py-3.5 bg-gray-800 rounded-full'>
                <div className='w-auto'>
                  <div className='flex flex-wrap items-center'>
                    <div className='w-auto'>
                      <a href='/'>
                        <img
                          src='images/EleleTechLogoText.png'
                          alt='Elele Tech Logo'
                          className='h-12'
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <div className='w-auto'>
                  <div className='flex flex-wrap items-center'>
                    <div className='w-auto hidden lg:block'>
                      <ul className='flex items-center justify-center'>
                        <li className='mr-9'>
                          <a
                            className='inline-block text-sm font-bold text-gray-200 hover:text-gray-300'
                            href='/about'
                          >
                            About
                          </a>
                        </li>
                        <li className='mr-9'>
                          <a
                            className='inline-block text-sm font-bold text-gray-200 hover:text-gray-300'
                            href='/services'
                          >
                            Services
                          </a>
                        </li>
                        <li className='mr-9'>
                          <a
                            className='inline-block text-sm font-bold text-gray-200 hover:text-gray-300'
                            href='/blog'
                          >
                            Blog
                          </a>
                        </li>
                        <li>
                          <a
                            className='inline-block text-sm font-bold text-gray-200 hover:text-gray-300'
                            href='/contact'
                          >
                            Contact
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className='w-auto'>
                  <div className='flex flex-wrap items-center'>
                    <div className='w-auto hidden lg:block'>
                      <div className='flex flex-wrap -m-2'>
                        <div className='w-full md:w-auto p-2'>
                          <a
                            className='block w-full px-4 py-2.5 text-sm text-center text-white font-bold bg-gray-800 hover:bg-gray-900 focus:ring-4 focus:ring-gray-500 rounded-full'
                            href='/'
                          >
                            Log In
                          </a>
                        </div>
                        <div className='w-full md:w-auto p-2'>
                          <a
                            className='block w-full px-4 py-2.5 text-sm text-center text-white font-bold bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-200 rounded-full'
                            href='/contact'
                          >
                            Get Started
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className='w-auto lg:hidden'>
                      <a className='inline-block' onClick={toggleMenu} href="#">
                        <svg
                          className='navbar-burger text-blue-500'
                          width={45}
                          height={45}
                          viewBox='0 0 56 56'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <rect
                            width={56}
                            height={56}
                            rx={28}
                            fill='currentColor'
                          />
                          <path
                            d='M37 32H19M37 24H19'
                            stroke='white'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className={isMenuOpen ? 'navbar-menu' : 'hidden navbar-menu fixed top-0 left-0 bottom-0 w-4/6 sm:max-w-xs z-50'}>
                <div className='navbar-backdrop fixed inset-0 bg-gray-800 opacity-80' />
                <nav className='relative z-10 px-9 pt-8 bg-gray-800 h-full overflow-y-auto'>
                  <div className='flex flex-wrap justify-between h-full'>
                    <div className='w-full'>
                      <div className='flex items-center justify-between -m-2'>
                        <div className='w-auto p-2'>
                          <a className='inline-block' href='#'>
                            <img
                              src='images/EleleTechLogoText.png'
                              alt='Elele Tech Logo'
                            />
                          </a>
                        </div>
                        <div className='w-auto p-2'>
                          <a className='inline-block navbar-burger' href='#' onClick={closeMenu}>
                            <svg
                              width={24}
                              height={24}
                              viewBox='0 0 24 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M6 18L18 6M6 6L18 18'
                                stroke='#ffffff'
                                strokeWidth={2}
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                            </svg>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className='flex flex-col justify-center py-8 w-full'>
                      <ul>
                        <li className='mb-9'>
                          <a
                            className='inline-block text-sm font-bold text-gray-200 hover:text-gray-300'
                            href='/about'
                          >
                            About
                          </a>
                        </li>
                        <li className='mb-9'>
                          <a
                            className='inline-block text-sm font-bold text-gray-200 hover:text-gray-300'
                            href='/services'
                          >
                            Services
                          </a>
                        </li>
                        <li className='mb-9'>
                          <a
                            className='inline-block text-sm font-bold text-gray-200 hover:text-gray-300'
                            href='/blog'
                          >
                            Blog
                          </a>
                        </li>
                        <li>
                          <a
                            className='inline-block text-sm font-bold text-gray-200 hover:text-gray-300'
                            href='/contact'
                          >
                            Contact
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className='flex flex-col justify-end w-full pb-8'>
                      <div className='flex flex-wrap -m-2'>
                        <div className='w-full p-2'>
                          <a
                            className='block w-full px-4 py-2.5 text-sm text-center text-white font-bold bg-gray-800 hover:bg-gray-900 focus:ring-4 focus:ring-gray-500 border border-gray-700 rounded-full'
                            href='/'
                          >
                            Log In
                          </a>
                        </div>
                        <div className='w-full p-2'>
                          <a
                            className='block w-full px-4 py-2.5 text-sm text-center text-white font-bold bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-200 rounded-full'
                            href='/contact'
                          >
                            Get Started
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </nav>
              </div>
            </div>
            <div
              className='px-8 py-20 bg-gray-800 overflow-hidden rounded-3xl'
              style={{
                backgroundImage:
                  'url("images/mountain.jpg")',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
              }}
            >
              <div className='md:max-w-2xl text-center mx-auto'>
                <span className='inline-block mb-3 text-sm text-blue-500 font-bold uppercase tracking-widest'>
                  Join Our Innovative Team at Elele.tech
                </span>
                <h1 className='font-heading mb-6 text-5xl lg:text-6xl text-white font-black tracking-tight'>
                  <span>Unluck Your </span>
                  <span className='text-transparent bg-clip-text bg-gradient-orange-light'>
                    Potential
                  </span>
                  <span>&nbsp;and Shape the Future with Us</span>
                </h1>
                <p className='mb-8 text-xl text-gray-500 font-bold'>
                  At Elele.tech, we believe in the power of innovation and the
                  potential of individuals to drive change. As a dynamic and
                  forward-thinking tech company, we are constantly pushing the
                  boundaries of what's possible. If you're passionate about
                  technology, eager to collaborate with talented professionals,
                  and want to make a meaningful impact, then you've come to the
                  right place. Explore our career opportunities and join us in
                  shaping the future of technology. We welcome diverse
                  perspectives and talents that will help us continue to deliver
                  cutting-edge solutions to our clients and partners. Discover
                  your potential and embark on an exciting journey with
                  Elele.tech today
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className='py-10 bg-gray-900 overflow-hidden'>
          <div className='container mx-auto px-4'>
            <div className='py-16 px-8 bg-gray-800 rounded-3xl'>
              <div className='max-w-7xl mx-auto'>
                <div className='mb-10 md:max-w-4xl mx-auto text-center'>
                  <span className='inline-block mb-4 text-sm text-blue-500 font-bold uppercase tracking-widest'>
                    We are hiring
                  </span>
                  <h2 className='font-heading mb-6 text-4xl md:text-5xl lg:text-6xl text-white font-black tracking-tight'>
                    Open positions
                  </h2>
                  <p className='md:max-w-md mx-auto text-gray-500 font-bold'>
                    This website is still under development. The buttons may not work at the moment. Sorry! Please check back later or email us via help@elele.tech
                  </p>
                </div>
                <div className='flex flex-wrap -m-3'>
                  <div className='w-full p-3'>
                    <div className='p-10 bg-gray-900 rounded-3xl'>
                      <div className='flex flex-wrap items-center -m-2'>
                        <div className='w-full md:w-1/2 p-2'>
                          <h2 className='font-heading text-2xl text-white font-black tracking-tight'>
                            Technician
                          </h2>
                        </div>
                        <div className='w-full md:w-1/2 p-2'>
                          <div className='flex flex-wrap items-center justify-end -m-5'>
                            <div className='w-full md:w-auto p-5'>
                              <div className='flex flex-wrap -m-3'>
                                <div className='flex flex-wrap items-center w-auto p-3'>
                                  <svg
                                    className='mr-2'
                                    width={20}
                                    height={20}
                                    viewBox='0 0 20 20'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'
                                  >
                                    <path
                                      d='M9.99935 1.66666C5.40768 1.66666 1.66602 5.40832 1.66602 9.99999C1.66602 14.5917 5.40768 18.3333 9.99935 18.3333C14.591 18.3333 18.3327 14.5917 18.3327 9.99999C18.3327 5.40832 14.591 1.66666 9.99935 1.66666ZM13.6243 12.975C13.5077 13.175 13.2993 13.2833 13.0827 13.2833C12.9743 13.2833 12.866 13.2583 12.766 13.1917L10.1827 11.65C9.54102 11.2667 9.06602 10.425 9.06602 9.68332V6.26666C9.06602 5.92499 9.34935 5.64166 9.69102 5.64166C10.0327 5.64166 10.316 5.92499 10.316 6.26666V9.68332C10.316 9.98332 10.566 10.425 10.8243 10.575L13.4077 12.1167C13.7077 12.2917 13.8077 12.675 13.6243 12.975Z'
                                      fill='#374151'
                                    />
                                  </svg>
                                  <p className='text-gray-500 font-bold'>
                                    Zero Hour Contract
                                  </p>
                                </div>
                                <div className='flex flex-wrap items-center w-auto p-3'>
                                  <svg
                                    className='mr-2'
                                    width={20}
                                    height={20}
                                    viewBox='0 0 20 20'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'
                                  >
                                    <path
                                      d='M17.1829 7.04168C16.3079 3.19168 12.9496 1.45834 9.99959 1.45834C9.99959 1.45834 9.99959 1.45834 9.99126 1.45834C7.04959 1.45834 3.68292 3.18334 2.80792 7.03334C1.83292 11.3333 4.46626 14.975 6.84959 17.2667C7.73292 18.1167 8.86626 18.5417 9.99959 18.5417C11.1329 18.5417 12.2663 18.1167 13.1413 17.2667C15.5246 14.975 18.1579 11.3417 17.1829 7.04168ZM9.99959 11.2167C8.54959 11.2167 7.37459 10.0417 7.37459 8.59168C7.37459 7.14168 8.54959 5.96668 9.99959 5.96668C11.4496 5.96668 12.6246 7.14168 12.6246 8.59168C12.6246 10.0417 11.4496 11.2167 9.99959 11.2167Z'
                                      fill='#374151'
                                    />
                                  </svg>
                                  <p className='text-gray-500 font-bold'>
                                    Warwickshire
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className='w-full md:w-auto p-5'>
                              <div className='flex flex-wrap md:justify-center -m-2'>
                                <div className='w-full md:w-auto p-2'>
                                  <a
                                    className='block w-full px-4 py-2.5 text-sm text-center text-white font-bold bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-200 rounded-full'
                                    href='#'
                                  >
                                    View job
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='w-full p-3'>
                    <div className='p-10 bg-gray-900 rounded-3xl'>
                      <div className='flex flex-wrap items-center -m-2'>
                        <div className='w-full md:w-1/2 p-2'>
                          <h2 className='font-heading text-2xl text-white font-black tracking-tight'>
                            Technican
                          </h2>
                        </div>
                        <div className='w-full md:w-1/2 p-2'>
                          <div className='flex flex-wrap items-center justify-end -m-5'>
                            <div className='w-full md:w-auto p-5'>
                              <div className='flex flex-wrap -m-3'>
                                <div className='flex flex-wrap items-center w-auto p-3'>
                                  <svg
                                    className='mr-2'
                                    width={20}
                                    height={20}
                                    viewBox='0 0 20 20'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'
                                  >
                                    <path
                                      d='M9.99935 1.66666C5.40768 1.66666 1.66602 5.40832 1.66602 9.99999C1.66602 14.5917 5.40768 18.3333 9.99935 18.3333C14.591 18.3333 18.3327 14.5917 18.3327 9.99999C18.3327 5.40832 14.591 1.66666 9.99935 1.66666ZM13.6243 12.975C13.5077 13.175 13.2993 13.2833 13.0827 13.2833C12.9743 13.2833 12.866 13.2583 12.766 13.1917L10.1827 11.65C9.54102 11.2667 9.06602 10.425 9.06602 9.68332V6.26666C9.06602 5.92499 9.34935 5.64166 9.69102 5.64166C10.0327 5.64166 10.316 5.92499 10.316 6.26666V9.68332C10.316 9.98332 10.566 10.425 10.8243 10.575L13.4077 12.1167C13.7077 12.2917 13.8077 12.675 13.6243 12.975Z'
                                      fill='#374151'
                                    />
                                  </svg>
                                  <p className='text-gray-500 font-bold'>
                                    Zero Hour Contract
                                  </p>
                                </div>
                                <div className='flex flex-wrap items-center w-auto p-3'>
                                  <svg
                                    className='mr-2'
                                    width={20}
                                    height={20}
                                    viewBox='0 0 20 20'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'
                                  >
                                    <path
                                      d='M17.1829 7.04168C16.3079 3.19168 12.9496 1.45834 9.99959 1.45834C9.99959 1.45834 9.99959 1.45834 9.99126 1.45834C7.04959 1.45834 3.68292 3.18334 2.80792 7.03334C1.83292 11.3333 4.46626 14.975 6.84959 17.2667C7.73292 18.1167 8.86626 18.5417 9.99959 18.5417C11.1329 18.5417 12.2663 18.1167 13.1413 17.2667C15.5246 14.975 18.1579 11.3417 17.1829 7.04168ZM9.99959 11.2167C8.54959 11.2167 7.37459 10.0417 7.37459 8.59168C7.37459 7.14168 8.54959 5.96668 9.99959 5.96668C11.4496 5.96668 12.6246 7.14168 12.6246 8.59168C12.6246 10.0417 11.4496 11.2167 9.99959 11.2167Z'
                                      fill='#374151'
                                    />
                                  </svg>
                                  <p className='text-gray-500 font-bold'>
                                    Manchester
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className='w-full md:w-auto p-5'>
                              <div className='flex flex-wrap md:justify-center -m-2'>
                                <div className='w-full md:w-auto p-2'>
                                  <a
                                    className='block w-full px-4 py-2.5 text-sm text-center text-gray-900 hover:text-white font-bold bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-200 rounded-full'
                                    href='#'
                                  >
                                    View job
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='w-full p-3'>
                    <div className='p-10 bg-gray-900 rounded-3xl'>
                      <div className='flex flex-wrap items-center -m-2'>
                        <div className='w-full md:w-1/2 p-2'>
                          <h2 className='font-heading text-2xl text-white font-black tracking-tight'>
                            Internships/Volunteer
                          </h2>
                        </div>
                        <div className='w-full md:w-1/2 p-2'>
                          <div className='flex flex-wrap items-center justify-end -m-5'>
                            <div className='w-full md:w-auto p-5'>
                              <div className='flex flex-wrap -m-3'>
                                <div className='flex flex-wrap items-center w-auto p-3'>
                                  <svg
                                    className='mr-2'
                                    width={20}
                                    height={20}
                                    viewBox='0 0 20 20'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'
                                  >
                                    <path
                                      d='M9.99935 1.66666C5.40768 1.66666 1.66602 5.40832 1.66602 9.99999C1.66602 14.5917 5.40768 18.3333 9.99935 18.3333C14.591 18.3333 18.3327 14.5917 18.3327 9.99999C18.3327 5.40832 14.591 1.66666 9.99935 1.66666ZM13.6243 12.975C13.5077 13.175 13.2993 13.2833 13.0827 13.2833C12.9743 13.2833 12.866 13.2583 12.766 13.1917L10.1827 11.65C9.54102 11.2667 9.06602 10.425 9.06602 9.68332V6.26666C9.06602 5.92499 9.34935 5.64166 9.69102 5.64166C10.0327 5.64166 10.316 5.92499 10.316 6.26666V9.68332C10.316 9.98332 10.566 10.425 10.8243 10.575L13.4077 12.1167C13.7077 12.2917 13.8077 12.675 13.6243 12.975Z'
                                      fill='#374151'
                                    />
                                  </svg>
                                  <p className='text-gray-500 font-bold'>
                                    Zero Hour Contract
                                  </p>
                                </div>
                                <div className='flex flex-wrap items-center w-auto p-3'>
                                  <svg
                                    className='mr-2'
                                    width={20}
                                    height={20}
                                    viewBox='0 0 20 20'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'
                                  >
                                    <path
                                      d='M17.1829 7.04168C16.3079 3.19168 12.9496 1.45834 9.99959 1.45834C9.99959 1.45834 9.99959 1.45834 9.99126 1.45834C7.04959 1.45834 3.68292 3.18334 2.80792 7.03334C1.83292 11.3333 4.46626 14.975 6.84959 17.2667C7.73292 18.1167 8.86626 18.5417 9.99959 18.5417C11.1329 18.5417 12.2663 18.1167 13.1413 17.2667C15.5246 14.975 18.1579 11.3417 17.1829 7.04168ZM9.99959 11.2167C8.54959 11.2167 7.37459 10.0417 7.37459 8.59168C7.37459 7.14168 8.54959 5.96668 9.99959 5.96668C11.4496 5.96668 12.6246 7.14168 12.6246 8.59168C12.6246 10.0417 11.4496 11.2167 9.99959 11.2167Z'
                                      fill='#374151'
                                    />
                                  </svg>
                                  <p className='text-gray-500 font-bold'>
                                    Remotly
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className='w-full md:w-auto p-5'>
                              <div className='flex flex-wrap md:justify-center -m-2'>
                                <div className='w-full md:w-auto p-2'>
                                  <a
                                    className='block w-full px-4 py-2.5 text-sm text-center text-gray-900 hover:text-white font-bold bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-200 rounded-full'
                                    href='#'
                                  >
                                    View job
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='w-full p-3'>
                    <div className='p-10 bg-gray-900 rounded-3xl'>
                      <div className='flex flex-wrap items-center -m-2'>
                        <div className='w-full md:w-1/2 p-2'>
                          <h2 className='font-heading text-2xl text-white font-black tracking-tight'>
                            Project Manager
                          </h2>
                        </div>
                        <div className='w-full md:w-1/2 p-2'>
                          <div className='flex flex-wrap items-center justify-end -m-5'>
                            <div className='w-full md:w-auto p-5'>
                              <div className='flex flex-wrap -m-3'>
                                <div className='flex flex-wrap items-center w-auto p-3'>
                                  <svg
                                    className='mr-2'
                                    width={20}
                                    height={20}
                                    viewBox='0 0 20 20'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'
                                  >
                                    <path
                                      d='M9.99935 1.66666C5.40768 1.66666 1.66602 5.40832 1.66602 9.99999C1.66602 14.5917 5.40768 18.3333 9.99935 18.3333C14.591 18.3333 18.3327 14.5917 18.3327 9.99999C18.3327 5.40832 14.591 1.66666 9.99935 1.66666ZM13.6243 12.975C13.5077 13.175 13.2993 13.2833 13.0827 13.2833C12.9743 13.2833 12.866 13.2583 12.766 13.1917L10.1827 11.65C9.54102 11.2667 9.06602 10.425 9.06602 9.68332V6.26666C9.06602 5.92499 9.34935 5.64166 9.69102 5.64166C10.0327 5.64166 10.316 5.92499 10.316 6.26666V9.68332C10.316 9.98332 10.566 10.425 10.8243 10.575L13.4077 12.1167C13.7077 12.2917 13.8077 12.675 13.6243 12.975Z'
                                      fill='#374151'
                                    />
                                  </svg>
                                  <p className='text-gray-500 font-bold'>
                                    Part time
                                  </p>
                                </div>
                                <div className='flex flex-wrap items-center w-auto p-3'>
                                  <svg
                                    className='mr-2'
                                    width={20}
                                    height={20}
                                    viewBox='0 0 20 20'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'
                                  >
                                    <path
                                      d='M17.1829 7.04168C16.3079 3.19168 12.9496 1.45834 9.99959 1.45834C9.99959 1.45834 9.99959 1.45834 9.99126 1.45834C7.04959 1.45834 3.68292 3.18334 2.80792 7.03334C1.83292 11.3333 4.46626 14.975 6.84959 17.2667C7.73292 18.1167 8.86626 18.5417 9.99959 18.5417C11.1329 18.5417 12.2663 18.1167 13.1413 17.2667C15.5246 14.975 18.1579 11.3417 17.1829 7.04168ZM9.99959 11.2167C8.54959 11.2167 7.37459 10.0417 7.37459 8.59168C7.37459 7.14168 8.54959 5.96668 9.99959 5.96668C11.4496 5.96668 12.6246 7.14168 12.6246 8.59168C12.6246 10.0417 11.4496 11.2167 9.99959 11.2167Z'
                                      fill='#374151'
                                    />
                                  </svg>
                                  <p className='text-gray-500 font-bold'>
                                    Remotly
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className='w-full md:w-auto p-5'>
                              <div className='flex flex-wrap md:justify-center -m-2'>
                                <div className='w-full md:w-auto p-2'>
                                  <a
                                    className='block w-full px-4 py-2.5 text-sm text-center text-gray-900 hover:text-white font-bold bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-200 rounded-full'
                                    href='#'
                                  >
                                    View job
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='w-full p-3'>
                    <div className='p-10 bg-gray-900 rounded-3xl'>
                      <div className='flex flex-wrap items-center -m-2'>
                        <div className='w-full md:w-1/2 p-2'>
                          <h2 className='font-heading text-2xl text-white font-black tracking-tight'>
                            Marketing Assistant
                          </h2>
                        </div>
                        <div className='w-full md:w-1/2 p-2'>
                          <div className='flex flex-wrap items-center justify-end -m-5'>
                            <div className='w-full md:w-auto p-5'>
                              <div className='flex flex-wrap -m-3'>
                                <div className='flex flex-wrap items-center w-auto p-3'>
                                  <svg
                                    className='mr-2'
                                    width={20}
                                    height={20}
                                    viewBox='0 0 20 20'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'
                                  >
                                    <path
                                      d='M9.99935 1.66666C5.40768 1.66666 1.66602 5.40832 1.66602 9.99999C1.66602 14.5917 5.40768 18.3333 9.99935 18.3333C14.591 18.3333 18.3327 14.5917 18.3327 9.99999C18.3327 5.40832 14.591 1.66666 9.99935 1.66666ZM13.6243 12.975C13.5077 13.175 13.2993 13.2833 13.0827 13.2833C12.9743 13.2833 12.866 13.2583 12.766 13.1917L10.1827 11.65C9.54102 11.2667 9.06602 10.425 9.06602 9.68332V6.26666C9.06602 5.92499 9.34935 5.64166 9.69102 5.64166C10.0327 5.64166 10.316 5.92499 10.316 6.26666V9.68332C10.316 9.98332 10.566 10.425 10.8243 10.575L13.4077 12.1167C13.7077 12.2917 13.8077 12.675 13.6243 12.975Z'
                                      fill='#374151'
                                    />
                                  </svg>
                                  <p className='text-gray-500 font-bold'>
                                    Part Time
                                  </p>
                                </div>
                                <div className='flex flex-wrap items-center w-auto p-3'>
                                  <svg
                                    className='mr-2'
                                    width={20}
                                    height={20}
                                    viewBox='0 0 20 20'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'
                                  >
                                    <path
                                      d='M17.1829 7.04168C16.3079 3.19168 12.9496 1.45834 9.99959 1.45834C9.99959 1.45834 9.99959 1.45834 9.99126 1.45834C7.04959 1.45834 3.68292 3.18334 2.80792 7.03334C1.83292 11.3333 4.46626 14.975 6.84959 17.2667C7.73292 18.1167 8.86626 18.5417 9.99959 18.5417C11.1329 18.5417 12.2663 18.1167 13.1413 17.2667C15.5246 14.975 18.1579 11.3417 17.1829 7.04168ZM9.99959 11.2167C8.54959 11.2167 7.37459 10.0417 7.37459 8.59168C7.37459 7.14168 8.54959 5.96668 9.99959 5.96668C11.4496 5.96668 12.6246 7.14168 12.6246 8.59168C12.6246 10.0417 11.4496 11.2167 9.99959 11.2167Z'
                                      fill='#374151'
                                    />
                                  </svg>
                                  <p className='text-gray-500 font-bold'>
                                    Remotly
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className='w-full md:w-auto p-5'>
                              <div className='flex flex-wrap md:justify-center -m-2'>
                                <div className='w-full md:w-auto p-2'>
                                  <a
                                    className='block w-full px-4 py-2.5 text-sm text-center text-gray-900 hover:text-white font-bold bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-200 rounded-full'
                                    href='#'
                                  >
                                    View job
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-10 bg-gray-900 overflow-hidden'>
          <div className='container mx-auto px-4'>
            <div className='py-16 px-8 bg-gray-800 rounded-3xl'>
              <div className='flex flex-col justify-centers'>
                <a className='inline-block max-w-max mx-auto mb-10' href='/'>
                  <img
                    src='images/EleleTechLogoText.png'
                    alt=''
                    className='h-16'
                  />
                </a>
                <ul className='-m-6 mb-7 text-center'>
                  <li className='inline-flex p-6'>
                    <a
                      className='inline-block text-gray-500 hover:text-gray-600 font-bold'
                      href='/services'
                    >
                      Services
                    </a>
                  </li>
                  <li className='inline-flex p-6'>
                    <a
                      className='inline-block text-gray-500 hover:text-gray-600 font-bold'
                      href='/about'
                    >
                      About Us
                    </a>
                  </li>
                  <li className='inline-flex p-6'>
                    <a
                      className='inline-block text-gray-500 hover:text-gray-600 font-bold'
                      href='/careers'
                    >
                      Careers
                    </a>
                  </li>
                  <li className='inline-flex p-6'>
                    <a
                      className='inline-block text-gray-500 hover:text-gray-600 font-bold'
                      href='/contact'
                    >
                      Contact Us
                    </a>
                  </li>
                </ul>
                <div className='flex flex-wrap justify-center -m-1.5 mb-10'>
                  <div className='w-auto p-1.5'>
                    <a
                      className='flex items-center justify-center w-10 h-10 bg-gray-700 rounded-full'
                      href='https://instagram.com/eleletech'
                    >
                      <img
                        src='images/insta-dark.svg'
                        alt=''
                      />
                    </a>
                  </div>
                  <div className='w-auto p-1.5'>
                    <a
                      className='flex items-center justify-center w-10 h-10 bg-gray-700 rounded-full'
                      href='https://twitter.com/elele_tech'
                    >
                      <img
                        src='images/twitter-dark.svg'
                        alt=''
                      />
                    </a>
                  </div>
                </div>
                <p
                  className='text-gray-500 text-center font-bold'
                  contentEditable='false'
                >
                  <span>&copy; Copyright. All rights reserved by </span>
                  <a className='text-blue-500 hover:text-blue-600' href='/'>
                    Elele Tech Services
                  </a>
                </p>
                <p
                  className='text-gray-600 text-center font-bold'
                  contentEditable='false'
                >
                  <span>Company Registered in UK and Wales: 14984769</span>
                </p>
                <p
                  className='text-gray-600 text-center font-bold'
                  contentEditable='false'
                >
                  <span>
                    Company Registered in State of California: 202355118927
                  </span>
                </p>
              </div>
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}

