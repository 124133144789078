import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function About() {
  
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const toggleMenu = () => {
    //console.log(isMenuOpen)
    setIsMenuOpen(!isMenuOpen);
  }
  const closeMenu = () => {
    setIsMenuOpen(false);
  }
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        <section className='pt-6 pb-20 bg-gray-900 overflow-hidden'>
          <div className='container mx-auto px-4'>
          <div className='mb-12'>
              <div className='flex items-center justify-between px-6 py-3.5 bg-gray-800 rounded-full'>
                <div className='w-auto'>
                  <div className='flex flex-wrap items-center'>
                    <div className='w-auto'>
                      <a href='/'>
                        <img
                          src='images/EleleTechLogoText.png'
                          alt='Elele Tech Logo'
                          className='h-12'
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <div className='w-auto'>
                  <div className='flex flex-wrap items-center'>
                    <div className='w-auto hidden lg:block'>
                      <ul className='flex items-center justify-center'>
                        <li className='mr-9'>
                          <a
                            className='inline-block text-sm font-bold text-gray-200 hover:text-gray-300'
                            href='/about'
                          >
                            About
                          </a>
                        </li>
                        <li className='mr-9'>
                          <a
                            className='inline-block text-sm font-bold text-gray-200 hover:text-gray-300'
                            href='/services'
                          >
                            Services
                          </a>
                        </li>
                        <li className='mr-9'>
                          <a
                            className='inline-block text-sm font-bold text-gray-200 hover:text-gray-300'
                            href='/blog'
                          >
                            Blog
                          </a>
                        </li>
                        <li>
                          <a
                            className='inline-block text-sm font-bold text-gray-200 hover:text-gray-300'
                            href='/contact'
                          >
                            Contact
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className='w-auto'>
                  <div className='flex flex-wrap items-center'>
                    <div className='w-auto hidden lg:block'>
                      <div className='flex flex-wrap -m-2'>
                        <div className='w-full md:w-auto p-2'>
                          <a
                            className='block w-full px-4 py-2.5 text-sm text-center text-white font-bold bg-gray-800 hover:bg-gray-900 focus:ring-4 focus:ring-gray-500 rounded-full'
                            href='/'
                          >
                            Log In
                          </a>
                        </div>
                        <div className='w-full md:w-auto p-2'>
                          <a
                            className='block w-full px-4 py-2.5 text-sm text-center text-white font-bold bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-200 rounded-full'
                            href='/contact'
                          >
                            Get Started
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className='w-auto lg:hidden'>
                      <a className='inline-block' onClick={toggleMenu} href="#">
                        <svg
                          className='navbar-burger text-blue-500'
                          width={45}
                          height={45}
                          viewBox='0 0 56 56'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <rect
                            width={56}
                            height={56}
                            rx={28}
                            fill='currentColor'
                          />
                          <path
                            d='M37 32H19M37 24H19'
                            stroke='white'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className={isMenuOpen ? 'navbar-menu' : 'hidden navbar-menu fixed top-0 left-0 bottom-0 w-4/6 sm:max-w-xs z-50'}>
                <div className='navbar-backdrop fixed inset-0 bg-gray-800 opacity-80' />
                <nav className='relative z-10 px-9 pt-8 bg-gray-800 h-full overflow-y-auto'>
                  <div className='flex flex-wrap justify-between h-full'>
                    <div className='w-full'>
                      <div className='flex items-center justify-between -m-2'>
                        <div className='w-auto p-2'>
                          <a className='inline-block' href='#'>
                            <img
                              src='images/EleleTechLogoText.png'
                              alt='Elele Tech Logo'
                            />
                          </a>
                        </div>
                        <div className='w-auto p-2'>
                          <a className='inline-block navbar-burger' href='#' onClick={closeMenu}>
                            <svg
                              width={24}
                              height={24}
                              viewBox='0 0 24 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M6 18L18 6M6 6L18 18'
                                stroke='#ffffff'
                                strokeWidth={2}
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                            </svg>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className='flex flex-col justify-center py-8 w-full'>
                      <ul>
                        <li className='mb-9'>
                          <a
                            className='inline-block text-sm font-bold text-gray-200 hover:text-gray-300'
                            href='/about'
                          >
                            About
                          </a>
                        </li>
                        <li className='mb-9'>
                          <a
                            className='inline-block text-sm font-bold text-gray-200 hover:text-gray-300'
                            href='/services'
                          >
                            Services
                          </a>
                        </li>
                        <li className='mb-9'>
                          <a
                            className='inline-block text-sm font-bold text-gray-200 hover:text-gray-300'
                            href='/blog'
                          >
                            Blog
                          </a>
                        </li>
                        <li>
                          <a
                            className='inline-block text-sm font-bold text-gray-200 hover:text-gray-300'
                            href='/contact'
                          >
                            Contact
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className='flex flex-col justify-end w-full pb-8'>
                      <div className='flex flex-wrap -m-2'>
                        <div className='w-full p-2'>
                          <a
                            className='block w-full px-4 py-2.5 text-sm text-center text-white font-bold bg-gray-800 hover:bg-gray-900 focus:ring-4 focus:ring-gray-500 border border-gray-700 rounded-full'
                            href='/'
                          >
                            Log In
                          </a>
                        </div>
                        <div className='w-full p-2'>
                          <a
                            className='block w-full px-4 py-2.5 text-sm text-center text-white font-bold bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-200 rounded-full'
                            href='/contact'
                          >
                            Get Started
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </nav>
              </div>
            </div>
            <div className='mb-8 p-8 bg-gray-800 rounded-3xl'>
              <div className='flex flex-wrap lg:items-center -m-8'>
                <div className='w-full md:w-1/2 p-8'>
                  <div className='md:max-w-lg mx-auto'>
                    <span className='inline-block mb-3 text-sm text-blue-500 font-bold uppercase tracking-widest'>
                      ELELE TECH SERVICES
                    </span>
                    <h1 className='font-heading mb-4 text-5xl text-white font-black tracking-tight'>
                      <span>Device repairs&nbsp;</span>
                      <span className='text-transparent bg-clip-text bg-gradient-red-light'>
                        faster
                      </span>
                      <span> than ever.</span>
                    </h1>
                    <p className='mb-6 text-xl text-gray-500 font-bold'>
                      Our goal is to establish a robust platform that offers
                      exceptional services and a seamless experience to all our
                      clients within the technology repair industry. We
                      prioritise efficiency and professionalism in delivering
                      our services, ensuring that clients receive their repaired
                      devices promptly and at an excellent quality level.
                    </p>
                    <div className='flex flex-wrap -m-2'>
                      <div className='w-full md:w-auto p-2'>
                        <a
                          className='block w-full px-4 py-2.5 text-sm text-center text-white font-bold bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-200 rounded-full'
                          href='/about'
                        >
                          Learn More
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='w-full md:w-1/2 p-8'>
                  <div className='max-w-max mx-auto md:mr-0 bg-white overflow-hidden rounded-3xl'>
                    <img
                      className='mx-auto'
                      src='images/work.png'
                      alt=''
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='px-8 py-11 bg-gray-800 rounded-3xl'>
              <div className='flex flex-wrap justify-center sm:justify-around -m-2'>
                <div className='w-full sm:w-auto p-2'>
                  <img
                    className='mx-auto h-16'
                    src='images/QzWRbe.png'
                    alt=''
                  />
                </div>
                <div className='w-full sm:w-auto p-2'>
                  <img
                    className='mx-auto h-16'
                    src='images/w8QY5m.png'
                    alt=''
                  />
                </div>
                <div className='w-full sm:w-auto p-2'>
                  <img
                    className='mx-auto h-16'
                    src='images/lailU7.png'
                    alt=''
                  />
                </div>
                <div className='w-full sm:w-auto pt-5'>
                  <img
                    className='mx-auto h-12'
                    src='images/gDv4oG.png'
                    alt=''
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-10 bg-gray-900 overflow-hidden'>
          <div className='container mx-auto px-4'>
            <div className='py-16 px-8 bg-gray-800 rounded-3xl'>
              <div className='max-w-7xl mx-auto'>
                <h2 className='font-heading max-w-xl mb-20 text-4xl md:text-5xl text-gray-100 font-black tracking-tight'>
                  We make things easy for you
                </h2>
                <div className='flex flex-wrap -m-3'>
                  <div className='w-full md:w-1/2 lg:w-1/4 p-3'>
                    <div className='flex flex-wrap items-center -m-3 mb-2'>
                      <div className='w-auto p-3'>
                        <div className='flex items-center justify-center w-16 h-16 text-xl text-gray-100 font-black bg-gray-700 rounded-full'>
                          1
                        </div>
                      </div>
                      <div className='flex-1 p-3'>
                        <div className='bg-gray-700 h-px' />
                      </div>
                    </div>
                    <div className='md:w-3/4'>
                      <h3 className='font-heading mb-4 text-2xl text-gray-100 font-bold'>
                        Contact Us
                      </h3>
                      <p className='text-gray-500 font-bold'>
                        Contact us with your device issue &amp; location.
                      </p>
                    </div>
                  </div>
                  <div className='w-full md:w-1/2 lg:w-1/4 p-3'>
                    <div className='flex flex-wrap items-center -m-3 mb-2'>
                      <div className='w-auto p-3'>
                        <div className='flex items-center justify-center w-16 h-16 text-xl text-white font-black bg-blue-500 rounded-full'>
                          2
                        </div>
                      </div>
                      <div className='flex-1 p-3'>
                        <div className='bg-gray-700 h-px' />
                      </div>
                    </div>
                    <div className='md:w-3/4'>
                      <h3 className='font-heading mb-4 text-2xl text-gray-100 font-bold'>
                        We Reply
                      </h3>
                      <p className='text-gray-500 font-bold'>
                        We reply as soon as possible with an evaluation and
                        price.
                      </p>
                    </div>
                  </div>
                  <div className='w-full md:w-1/2 lg:w-1/4 p-3'>
                    <div className='flex flex-wrap items-center -m-3 mb-2'>
                      <div className='w-auto p-3'>
                        <div className='flex items-center justify-center w-16 h-16 text-xl text-gray-100 font-black bg-gray-700 rounded-full'>
                          3
                        </div>
                      </div>
                      <div className='flex-1 p-3'>
                        <div className='bg-gray-700 h-px' />
                      </div>
                    </div>
                    <div className='md:w-3/4'>
                      <h3 className='font-heading mb-4 text-2xl text-gray-100 font-bold'>
                        Collect/DropOff
                      </h3>
                      <p className='text-gray-500 font-bold'>
                        You choose what is easier for you or available. Then we
                        fix your device (sometimes on site).
                      </p>
                    </div>
                  </div>
                  <div className='w-full md:w-1/2 lg:w-1/4 p-3'>
                    <div className='flex flex-wrap items-center -m-3 mb-2'>
                      <div className='w-auto p-3'>
                        <div className='flex items-center justify-center w-16 h-16 text-xl text-gray-100 font-black bg-gray-700 rounded-full'>
                          4
                        </div>
                      </div>
                      <div className='flex-1 p-3'>
                        <div className='bg-gray-700 h-px' />
                      </div>
                    </div>
                    <div className='md:w-3/4'>
                      <h3 className='font-heading mb-4 text-2xl text-gray-100 font-bold'>
                        Deliver
                      </h3>
                      <p className='text-gray-500 font-bold'>
                        We give your device back with a full analysis and
                        evaluation of the work done.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-10 bg-gray-900 overflow-hidden'>
          <div className='container mx-auto px-4'>
            <div className='py-16 px-8 md:px-16 bg-gradient-red rounded-3xl'>
              <div className='flex flex-wrap items-center -m-4'>
                <div className='w-full md:w-1/2 p-4'>
                  <h2 className='font-heading text-4xl md:text-5xl text-white font-black tracking-tight'>
                    Ready to dive in? Let’s start together.
                  </h2>
                </div>
                <div className='w-full md:w-1/2 p-4'>
                  <div className='flex flex-wrap md:justify-end -m-2'>
                    <div className='w-auto p-2'>
                      <a
                        className='block w-full px-8 py-3.5 text-lg text-center text-white font-bold bg-gray-900 hover:bg-gray-800 focus:ring-4 focus:ring-gray-600 rounded-full'
                        href='/contact'
                      >
                        Get Started
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-10 bg-gray-900 overflow-hidden'>
          <div className='container mx-auto px-4'>
            <div className='py-16 px-8 bg-gray-800 rounded-3xl'>
              <div className='flex flex-col justify-centers'>
                <a className='inline-block max-w-max mx-auto mb-10' href=''>
                  <img
                    src='images/EleleTechLogoText.png'
                    alt='Elele Tech Services'
                    className='h-16'
                  />
                </a>
                <ul className='-m-6 mb-7 text-center'>
                  <li className='inline-flex p-6'>
                    <a
                      className='inline-block text-gray-500 hover:text-gray-600 font-bold'
                      href='/services'
                    >
                      Services
                    </a>
                  </li>
                  <li className='inline-flex p-6'>
                    <a
                      className='inline-block text-gray-500 hover:text-gray-600 font-bold'
                      href='/about'
                    >
                      About Us
                    </a>
                  </li>
                  <li className='inline-flex p-6'>
                    <a
                      className='inline-block text-gray-500 hover:text-gray-600 font-bold'
                      href='/careers'
                    >
                      Careers
                    </a>
                  </li>
                  <li className='inline-flex p-6'>
                    <a
                      className='inline-block text-gray-500 hover:text-gray-600 font-bold'
                      href='/contact'
                    >
                      Contact Us
                    </a>
                  </li>
                </ul>
                <div className='flex flex-wrap justify-center -m-1.5 mb-10'>
                  <div className='w-auto p-1.5'>
                    <a
                      className='flex items-center justify-center w-10 h-10 bg-gray-700 rounded-full'
                      href='https://instagram.com/eleletech'
                    >
                      <img
                        src='/images/insta-dark.svg'
                        alt=''
                      />
                    </a>
                  </div>
                  <div className='w-auto p-1.5'>
                    <a
                      className='flex items-center justify-center w-10 h-10 bg-gray-700 rounded-full'
                      href='https://twitter.com/elele_tech'
                    >
                      <img
                        src='images/twitter-dark.svg'
                        alt=''
                      />
                    </a>
                  </div>
                </div>
                <p
                  className='text-gray-500 text-center font-bold'
                  contentEditable='false'
                >
                  <span>&copy; Copyright. All rights reserved by </span>
                  <a className='text-blue-500 hover:text-blue-600' href='/'>
                    Elele Tech Services
                  </a>
                </p>
                <p
                  className='text-gray-600 text-center font-bold'
                  contentEditable='false'
                >
                  <span>Company Registered in UK and Wales: 14984769</span>
                </p>
                <p
                  className='text-gray-600 text-center font-bold'
                  contentEditable='false'
                >
                  <span>
                    Company Registered in State of California: 202355118927
                  </span>
                </p>
              </div>
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}

