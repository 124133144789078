import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Blog() {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const toggleMenu = () => {
    //console.log(isMenuOpen)
    setIsMenuOpen(!isMenuOpen);
  }
  const closeMenu = () => {
    setIsMenuOpen(false);
  }
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        <section className='py-6 bg-gray-900'>
          <div className='container mx-auto px-4'>
            <div className='mb-12'>
              <div className='flex items-center justify-between px-6 py-3.5 bg-gray-800 rounded-full'>
                <div className='w-auto'>
                  <div className='flex flex-wrap items-center'>
                    <div className='w-auto'>
                      <a href='/'>
                        <img
                          src='images/EleleTechLogoText.png'
                          alt='Elele Tech Logo'
                          className='h-12'
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <div className='w-auto'>
                  <div className='flex flex-wrap items-center'>
                    <div className='w-auto hidden lg:block'>
                      <ul className='flex items-center justify-center'>
                        <li className='mr-9'>
                          <a
                            className='inline-block text-sm font-bold text-gray-200 hover:text-gray-300'
                            href='/about'
                          >
                            About
                          </a>
                        </li>
                        <li className='mr-9'>
                          <a
                            className='inline-block text-sm font-bold text-gray-200 hover:text-gray-300'
                            href='/services'
                          >
                            Services
                          </a>
                        </li>
                        <li className='mr-9'>
                          <a
                            className='inline-block text-sm font-bold text-gray-200 hover:text-gray-300'
                            href='/blog'
                          >
                            Blog
                          </a>
                        </li>
                        <li>
                          <a
                            className='inline-block text-sm font-bold text-gray-200 hover:text-gray-300'
                            href='/contact'
                          >
                            Contact
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className='w-auto'>
                  <div className='flex flex-wrap items-center'>
                    <div className='w-auto hidden lg:block'>
                      <div className='flex flex-wrap -m-2'>
                        <div className='w-full md:w-auto p-2'>
                          <a
                            className='block w-full px-4 py-2.5 text-sm text-center text-white font-bold bg-gray-800 hover:bg-gray-900 focus:ring-4 focus:ring-gray-500 rounded-full'
                            href='/'
                          >
                            Log In
                          </a>
                        </div>
                        <div className='w-full md:w-auto p-2'>
                          <a
                            className='block w-full px-4 py-2.5 text-sm text-center text-white font-bold bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-200 rounded-full'
                            href='/contact'
                          >
                            Get Started
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className='w-auto lg:hidden'>
                      <a className='inline-block' onClick={toggleMenu} href="#">
                        <svg
                          className='navbar-burger text-blue-500'
                          width={45}
                          height={45}
                          viewBox='0 0 56 56'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <rect
                            width={56}
                            height={56}
                            rx={28}
                            fill='currentColor'
                          />
                          <path
                            d='M37 32H19M37 24H19'
                            stroke='white'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className={isMenuOpen ? 'navbar-menu' : 'hidden navbar-menu fixed top-0 left-0 bottom-0 w-4/6 sm:max-w-xs z-50'}>
                <div className='navbar-backdrop fixed inset-0 bg-gray-800 opacity-80' />
                <nav className='relative z-10 px-9 pt-8 bg-gray-800 h-full overflow-y-auto'>
                  <div className='flex flex-wrap justify-between h-full'>
                    <div className='w-full'>
                      <div className='flex items-center justify-between -m-2'>
                        <div className='w-auto p-2'>
                          <a className='inline-block' href='#'>
                            <img
                              src='images/EleleTechLogoText.png'
                              alt='Elele Tech Logo'
                            />
                          </a>
                        </div>
                        <div className='w-auto p-2'>
                          <a className='inline-block navbar-burger' href='#' onClick={closeMenu}>
                            <svg
                              width={24}
                              height={24}
                              viewBox='0 0 24 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M6 18L18 6M6 6L18 18'
                                stroke='#ffffff'
                                strokeWidth={2}
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                            </svg>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className='flex flex-col justify-center py-8 w-full'>
                      <ul>
                        <li className='mb-9'>
                          <a
                            className='inline-block text-sm font-bold text-gray-200 hover:text-gray-300'
                            href='/about'
                          >
                            About
                          </a>
                        </li>
                        <li className='mb-9'>
                          <a
                            className='inline-block text-sm font-bold text-gray-200 hover:text-gray-300'
                            href='/services'
                          >
                            Services
                          </a>
                        </li>
                        <li className='mb-9'>
                          <a
                            className='inline-block text-sm font-bold text-gray-200 hover:text-gray-300'
                            href='/blog'
                          >
                            Blog
                          </a>
                        </li>
                        <li>
                          <a
                            className='inline-block text-sm font-bold text-gray-200 hover:text-gray-300'
                            href='/contact'
                          >
                            Contact
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className='flex flex-col justify-end w-full pb-8'>
                      <div className='flex flex-wrap -m-2'>
                        <div className='w-full p-2'>
                          <a
                            className='block w-full px-4 py-2.5 text-sm text-center text-white font-bold bg-gray-800 hover:bg-gray-900 focus:ring-4 focus:ring-gray-500 border border-gray-700 rounded-full'
                            href='/'
                          >
                            Log In
                          </a>
                        </div>
                        <div className='w-full p-2'>
                          <a
                            className='block w-full px-4 py-2.5 text-sm text-center text-white font-bold bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-200 rounded-full'
                            href='/contact'
                          >
                            Get Started
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </section>
        <section className='py-10 bg-gray-900 overflow-hidden'>
          <div className='container mx-auto px-4'>
            <div
              className='relative py-20 px-8 rounded-3xl'
              style={{
                backgroundImage:
                  'url("images/blog-screen3.png")',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
              }}
            >
              <div className='max-w-7xl mx-auto'>
                <div className='p-10 max-w-md rounded-3xl bg-gray-900'>
                  <p className='mb-3 text-xs text-gray-500 font-bold'>
                    blog.eleletech.services
                  </p>
                  <h2 className='font-heading mb-6 text-4xl md:text-5xl text-white font-black'>
                    Explore Our Latest Guides and Expertise on Our Blog
                  </h2>
                  <p className='mb-28 text-gray-500 font-bold'>
                    Welcome to the Elele.tech blog, your destination for the
                    latest insights, trends, and expertise in the world of
                    technology and innovation. Our team of experts and thought
                    leaders regularly share valuable information, tips, and
                    industry updates to keep you informed and inspired. Whether
                    you're a tech enthusiast, a business leader, or simply
                    curious about the ever-evolving tech landscape, our blog is
                    your go-to resource for informative and engaging content.
                    Dive into our articles, stay informed, and join the
                    conversation as we explore the exciting world of technology
                    together.
                  </p>
                  <a
                    className='flex items-center text-blue-500 hover:text-blue-600'
                    href='https://blog.eleletech.services'
                    target='_blank'
                  >
                    <span className='mr-0.5 font-black'>Visit Blog</span>
                    <svg
                      width={16}
                      height={16}
                      viewBox='0 0 16 16'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M6.6805 3.5465L8.82049 5.6865L10.1338 6.99317C10.6872 7.5465 10.6872 8.4465 10.1338 8.99983L6.6805 12.4532C6.22716 12.9065 5.45383 12.5798 5.45383 11.9465V8.2065V4.05317C5.45383 3.41317 6.22716 3.09317 6.6805 3.5465Z'
                        fill='currentColor'
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-10 bg-gray-900 overflow-hidden'>
          <div className='container mx-auto px-4'>
            <div className='py-16 px-8 bg-gray-800 rounded-3xl'>
              <div className='flex flex-col justify-centers'>
                <a className='inline-block max-w-max mx-auto mb-10' href='/'>
                  <img
                    src='images/EleleTechLogoText.png'
                    alt=''
                    className='h-16'
                  />
                </a>
                <ul className='-m-6 mb-7 text-center'>
                  <li className='inline-flex p-6'>
                    <a
                      className='inline-block text-gray-500 hover:text-gray-600 font-bold'
                      href='/services'
                    >
                      Services
                    </a>
                  </li>
                  <li className='inline-flex p-6'>
                    <a
                      className='inline-block text-gray-500 hover:text-gray-600 font-bold'
                      href='/about'
                    >
                      About Us
                    </a>
                  </li>
                  <li className='inline-flex p-6'>
                    <a
                      className='inline-block text-gray-500 hover:text-gray-600 font-bold'
                      href='/careers'
                    >
                      Careers
                    </a>
                  </li>
                  <li className='inline-flex p-6'>
                    <a
                      className='inline-block text-gray-500 hover:text-gray-600 font-bold'
                      href='/contact'
                    >
                      Contact Us
                    </a>
                  </li>
                </ul>
                <div className='flex flex-wrap justify-center -m-1.5 mb-10'>
                  <div className='w-auto p-1.5'>
                    <a
                      className='flex items-center justify-center w-10 h-10 bg-gray-700 rounded-full'
                      href='https://instagram.com/eleletech'
                    >
                      <img
                        src='images/insta-dark.svg'
                        alt=''
                      />
                    </a>
                  </div>
                  <div className='w-auto p-1.5'>
                    <a
                      className='flex items-center justify-center w-10 h-10 bg-gray-700 rounded-full'
                      href='https://twitter.com/elele_tech'
                    >
                      <img
                        src='images/twitter-dark.svg'
                        alt=''
                      />
                    </a>
                  </div>
                  <div className='w-auto p-1.5' />
                </div>
                <p
                  className='text-gray-500 text-center font-bold'
                  contentEditable='false'
                >
                  <span>&copy; Copyright. All rights reserved by </span>
                  <a className='text-blue-500 hover:text-blue-600' href='/'>
                    Elele Tech Services
                  </a>
                </p>
                <p
                  className='text-gray-600 text-center font-bold'
                  contentEditable='false'
                >
                  <span>Company Registered in UK and Wales: 14984769</span>
                </p>
                <p
                  className='text-gray-600 text-center font-bold'
                  contentEditable='false'
                >
                  <span>
                    Company Registered in State of California: 202355118927
                  </span>
                </p>
              </div>
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}

