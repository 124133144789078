import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Index() {

  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const toggleMenu = () => {
    //console.log(isMenuOpen)
    setIsMenuOpen(!isMenuOpen);
  }
  const closeMenu = () => {
    setIsMenuOpen(false);
  }
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        <script src="js/main.js"></script>
        <section className='pt-6 pb-20 bg-gray-900 overflow-hidden'>
          <div className='container mx-auto px-4'>
            <div className='mb-12'>
              <div className='flex items-center justify-between px-6 py-3.5 bg-gray-800 rounded-full'>
                <div className='w-auto'>
                  <div className='flex flex-wrap items-center'>
                    <div className='w-auto'>
                      <a href='/'>
                        <img
                          src='images/EleleTechLogoText.png'
                          alt='Elele Tech Logo'
                          className='h-12'
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <div className='w-auto'>
                  <div className='flex flex-wrap items-center'>
                    <div className='w-auto hidden lg:block'>
                      <ul className='flex items-center justify-center'>
                        <li className='mr-9'>
                          <a
                            className='inline-block text-sm font-bold text-gray-200 hover:text-gray-300'
                            href='/about'
                          >
                            About
                          </a>
                        </li>
                        <li className='mr-9'>
                          <a
                            className='inline-block text-sm font-bold text-gray-200 hover:text-gray-300'
                            href='/services'
                          >
                            Services
                          </a>
                        </li>
                        <li className='mr-9'>
                          <a
                            className='inline-block text-sm font-bold text-gray-200 hover:text-gray-300'
                            href='/blog'
                          >
                            Blog
                          </a>
                        </li>
                        <li>
                          <a
                            className='inline-block text-sm font-bold text-gray-200 hover:text-gray-300'
                            href='/contact'
                          >
                            Contact
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className='w-auto'>
                  <div className='flex flex-wrap items-center'>
                    <div className='w-auto hidden lg:block'>
                      <div className='flex flex-wrap -m-2'>
                        <div className='w-full md:w-auto p-2'>
                          <a
                            className='block w-full px-4 py-2.5 text-sm text-center text-white font-bold bg-gray-800 hover:bg-gray-900 focus:ring-4 focus:ring-gray-500 rounded-full'
                            href='/'
                          >
                            Log In
                          </a>
                        </div>
                        <div className='w-full md:w-auto p-2'>
                          <a
                            className='block w-full px-4 py-2.5 text-sm text-center text-white font-bold bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-200 rounded-full'
                            href='/contact'
                          >
                            Get Started
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className='w-auto lg:hidden'>
                      <a className='inline-block' onClick={toggleMenu} href="#">
                        <svg
                          className='navbar-burger text-blue-500'
                          width={45}
                          height={45}
                          viewBox='0 0 56 56'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <rect
                            width={56}
                            height={56}
                            rx={28}
                            fill='currentColor'
                          />
                          <path
                            d='M37 32H19M37 24H19'
                            stroke='white'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className={isMenuOpen ? 'navbar-menu' : 'hidden navbar-menu fixed top-0 left-0 bottom-0 w-4/6 sm:max-w-xs z-50'}>
                <div className='navbar-backdrop fixed inset-0 bg-gray-800 opacity-80' />
                <nav className='relative z-10 px-9 pt-8 bg-gray-800 h-full overflow-y-auto'>
                  <div className='flex flex-wrap justify-between h-full'>
                    <div className='w-full'>
                      <div className='flex items-center justify-between -m-2'>
                        <div className='w-auto p-2'>
                          <a className='inline-block' href='#'>
                            <img
                              src='images/EleleTechLogoText.png'
                              alt='Elele Tech Logo'
                            />
                          </a>
                        </div>
                        <div className='w-auto p-2'>
                          <a className='inline-block navbar-burger' href='#' onClick={closeMenu}>
                            <svg
                              width={24}
                              height={24}
                              viewBox='0 0 24 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M6 18L18 6M6 6L18 18'
                                stroke='#ffffff'
                                strokeWidth={2}
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                            </svg>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className='flex flex-col justify-center py-8 w-full'>
                      <ul>
                        <li className='mb-9'>
                          <a
                            className='inline-block text-sm font-bold text-gray-200 hover:text-gray-300'
                            href='/about'
                          >
                            About
                          </a>
                        </li>
                        <li className='mb-9'>
                          <a
                            className='inline-block text-sm font-bold text-gray-200 hover:text-gray-300'
                            href='/services'
                          >
                            Services
                          </a>
                        </li>
                        <li className='mb-9'>
                          <a
                            className='inline-block text-sm font-bold text-gray-200 hover:text-gray-300'
                            href='/blog'
                          >
                            Blog
                          </a>
                        </li>
                        <li>
                          <a
                            className='inline-block text-sm font-bold text-gray-200 hover:text-gray-300'
                            href='/contact'
                          >
                            Contact
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className='flex flex-col justify-end w-full pb-8'>
                      <div className='flex flex-wrap -m-2'>
                        <div className='w-full p-2'>
                          <a
                            className='block w-full px-4 py-2.5 text-sm text-center text-white font-bold bg-gray-800 hover:bg-gray-900 focus:ring-4 focus:ring-gray-500 border border-gray-700 rounded-full'
                            href='/'
                          >
                            Log In
                          </a>
                        </div>
                        <div className='w-full p-2'>
                          <a
                            className='block w-full px-4 py-2.5 text-sm text-center text-white font-bold bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-200 rounded-full'
                            href='/contact'
                          >
                            Get Started
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </nav>
              </div>
            </div>
            <div className='flex flex-wrap lg:items-center pb-2 -m-8'>
              <div className='w-full md:w-1/2 p-8'>
                <div className='md:max-w-lg'>
                  <span className='inline-block mb-3 text-sm text-blue-500 font-bold uppercase tracking-widest'>
                    Your Gateway to Seamless Device Performance
                  </span>
                  <h1 className='font-heading mb-4 text-5xl text-white font-black tracking-tight'>
                    EleleTech – Where Repairs Meet Excellence
                  </h1>
                  <p className='mb-4 text-gray-500 font-bold'>
                    At EleleTech, we understand the importance of your devices
                    in your daily life, which is why we do more than just fix
                    them – we enhance their performance.
                  </p>
                  <div className='flex flex-wrap mb-11 -m-2'>
                    <div className='w-full md:w-auto p-2'>
                      <a
                        className='block w-full px-4 py-2.5 text-sm text-center text-gray-100 font-bold bg-gray-800 hover:bg-gray-700 focus:ring-4 focus:ring-gray-600 rounded-full'
                        href='/contact'
                      >
                        Contact Us
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className='w-full md:w-1/2 p-8'>
                <div className='max-w-max mx-auto md:mr-0 bg-gray-800 rounded-3xl'>
                  <img
                    className='mx-auto'
                    src='images/pexels-djordje-petrovic-2102416.jpg'
                    alt=''
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-20 bg-gray-900 overflow-hidden'>
          <div className='container mx-auto px-4'>
            <div className='flex flex-wrap items-center -m-8 lg:-m-16'>
              <div className='w-full md:w-1/2 p-8 lg:p-16'>
                <div className='p-8 bg-gray-800 rounded-3xl'>
                  <img
                    className='mb-40 h-24'
                    src='images/EleleTechLogoText.png'
                    alt=''
                  />
                  <h2 className='font-heading mb-4 text-4xl md:text-5xl text-gray-100 font-black tracking-tight'>
                    Our Philosophy
                  </h2>
                  <p className='text-gray-500 font-bold'>
                    At EleleTech, our philosophy is rooted in the belief that
                    technology should enrich and simplify life, not complicate
                    it. We foster a work environment where innovation,
                    collaboration, and customer-centricity are paramount. Each
                    member of our team is dedicated to continuous learning and
                    improvement, driven by a passion for technology and a
                    commitment to excellence.&nbsp;
                  </p>
                </div>
              </div>
              <div className='w-full md:w-1/2 p-8 lg:p-16'>
                <div className='flex flex-wrap -m-4'>
                  <div className='w-auto lg:w-1/2 p-4'>
                    <div className='flex flex-wrap items-center -m-2'>
                      <div className='w-auto p-2'>
                        <img
                          className='rounded-3xl h-16'
                          src='images/jack.png'
                          alt=''
                        />
                      </div>
                      <div className='flex-1 p-2'>
                        <h3 className='font-heading mb-0.5 text-xl text-gray-100 font-bold'>
                          Jack
                        </h3>
                        <p className='text-sm text-gray-700 font-bold'>
                          Warwickshire Director
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='w-auto lg:w-1/2 p-4'>
                    <div className='flex flex-wrap items-center -m-2'>
                      <div className='w-auto p-2'>
                        <img
                          className='rounded-3xl h-16'
                          src='images/hunter.png'
                          alt=''
                        />
                      </div>
                      <div className='flex-1 p-2'>
                        <h3 className='font-heading mb-0.5 text-xl text-gray-100 font-bold'>
                          Hunter
                        </h3>
                        <p className='text-sm text-gray-700 font-bold'>
                          California Director
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='w-auto lg:w-1/2 p-4'>
                    <div className='flex flex-wrap items-center -m-2'>
                      <div className='w-auto p-2'>
                        <img
                          className='rounded-3xl h-16'
                          src='images/matty.png'
                          alt=''
                        />
                      </div>
                      <div className='flex-1 p-2'>
                        <h3 className='font-heading mb-0.5 text-xl text-gray-100 font-bold'>
                          Matty
                        </h3>
                        <p className='text-sm text-gray-700 font-bold'>
                          Manchester Technician
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='w-auto lg:w-1/2 p-4'>
                    <div className='flex flex-wrap items-center -m-2'>
                      <div className='w-auto p-2'>
                        <img
                          className='rounded-3xl'
                          src='images/2744da52-9c04-42b1-a2ed-c4b66a9ed58d-profile-image-70x70.png'
                          alt=''
                        />
                      </div>
                      <div className='flex-1 p-2'>
                        <h3 className='font-heading mb-0.5 text-xl text-gray-100 font-bold'>
                          Clary
                        </h3>
                        <p className='text-sm text-gray-700 font-bold'>
                          Marketing Manager
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='w-auto lg:w-1/2 p-4'>
                    <div className='flex flex-wrap items-center -m-2'>
                      <div className='w-auto p-2'>
                        <img
                          className='rounded-3xl h-16'
                          src='images/download.svg'
                          alt=''
                        />
                      </div>
                      <div className='flex-1 p-2'>
                        <h3 className='font-heading mb-0.5 text-xl text-gray-100 font-bold'>
                          James
                        </h3>
                        <p className='text-sm text-gray-700 font-bold'>
                          Warwickshire Technican
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-14 pb-10 bg-gray-900 overflow-hidden'>
          <div className='container mx-auto px-4'>
            <div className='flex flex-wrap -m-4'>
              <div className='w-full md:w-1/2 p-4'>
                <div className='p-8 h-full bg-gray-800 border border-gray-800 rounded-3xl'>
                  <h2 className='font-heading mb-6 text-5xl md:text-6xl text-blue-500 font-black tracking-tight'>
                    10+
                  </h2>
                  <h3 className='font-heading mb-2 text-xl text-gray-300 font-bold'>
                    Satisfied clients
                  </h3>
                  <p className='text-gray-600 font-bold'>
                    From Individuals to Business worlwide
                  </p>
                </div>
              </div>
              <div className='w-full md:w-1/2 p-4'>
                <div className='p-8 h-full bg-gray-800 border border-gray-800 rounded-3xl'>
                  <h2 className='font-heading mb-6 text-5xl md:text-6xl text-blue-500 font-black tracking-tight'>
                    76k+/m
                  </h2>
                  <h3 className='font-heading mb-2 text-xl text-gray-300 font-bold'>
                    Website page views
                  </h3>
                  <p className='text-gray-600 font-bold'>
                    Website views across mulitple websites and platforms.
                  </p>
                </div>
              </div>
              <div className='w-full md:w-1/2 p-4'>
                <div className='p-8 h-full bg-gray-800 border border-gray-800 rounded-3xl'>
                  <h2 className='font-heading mb-6 text-5xl md:text-6xl text-blue-500 font-black tracking-tight'>
                    99.95%
                  </h2>
                  <h3 className='font-heading mb-2 text-xl text-gray-300 font-bold'>
                    Accuracy Rate
                  </h3>
                  <p className='text-gray-600 font-bold'>
                    When fulfilling orders from clients
                  </p>
                </div>
              </div>
              <div className='w-full md:w-1/2 p-4'>
                <div className='p-8 h-full bg-gray-800 border border-gray-800 rounded-3xl'>
                  <h2 className='font-heading mb-6 text-5xl md:text-6xl text-blue-500 font-black tracking-tight'>
                    2x
                  </h2>
                  <h3 className='font-heading mb-2 text-xl text-gray-300 font-bold'>
                    Faster than our competitors*
                  </h3>
                  <p className='text-gray-600 font-bold'>
                    *Only on selected corporations
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-10 bg-gray-900 overflow-hidden'>
          <div className='container mx-auto px-4'>
            <div className='py-20 px-8 text-center rounded-3xl bg-gradient-black'>
              <div className='md:max-w-xl mx-auto'>
                <h2 className='font-heading mb-12 text-4xl md:text-5xl text-white font-black tracking-tight'>
                  Are you ready to get help from our expert technicans?
                </h2>
                <div className='flex flex-wrap items-center -m-3 mb-4'>
                  <div className='w-full md:flex-1 p-2'>
                    <form method="post" action="https://urban-pelican.pikapod.net/subscription/form">
                      <input
                        className='px-6 py-3.5 w-full text-lg text-gray-200 font-bold bg-gray-700 outline-none focus:ring-4 focus:ring-blue-200 placeholder-gray-200 rounded-full'
                        id='headerDarkInput5-1'
                        type='email'
                        name="email"
                        placeholder='Email address'
                      />
                      <input type="hidden" id="ae218" name="l" checked value="ae218fd8-ae7f-46fb-bccd-82322abfff02" />
                      <div className='w-full md:w-auto p-2'>
                        <div className='flex flex-wrap justify-center -m-2'>
                          <div className='w-full md:w-auto p-2'>
                            <input
                              className='block w-full px-8 py-3.5 text-lg text-center text-white font-bold bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-200 rounded-full'
                              value="Subscribe"
                              type="submit"
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </section>
        <section className='py-10 bg-gray-900 overflow-hidden'>
          <div className='container mx-auto px-4'>
            <div className='py-16 px-8 bg-gray-800 rounded-3xl'>
              <div className='flex flex-col justify-centers'>
                <a className='inline-block max-w-max mx-auto mb-10' href='/'>
                  <img
                    src='images/EleleTechLogoText.png'
                    alt=''
                    className='h-16'
                  />
                </a>
                <ul className='-m-6 mb-7 text-center'>
                  <li className='inline-flex p-6'>
                    <a
                      className='inline-block text-gray-500 hover:text-gray-600 font-bold'
                      href='/services'
                    >
                      Services
                    </a>
                  </li>
                  <li className='inline-flex p-6'>
                    <a
                      className='inline-block text-gray-500 hover:text-gray-600 font-bold'
                      href='/about'
                    >
                      About Us
                    </a>
                  </li>
                  <li className='inline-flex p-6'>
                    <a
                      className='inline-block text-gray-500 hover:text-gray-600 font-bold'
                      href='/careers'
                    >
                      Careers
                    </a>
                  </li>
                  <li className='inline-flex p-6'>
                    <a
                      className='inline-block text-gray-500 hover:text-gray-600 font-bold'
                      href='/contact'
                    >
                      Contact Us
                    </a>
                  </li>
                </ul>
                <div className='flex flex-wrap justify-center -m-1.5 mb-10'>
                  <div className='w-auto p-1.5'>
                    <a
                      className='flex items-center justify-center w-10 h-10 bg-gray-700 rounded-full'
                      href='https://www.instagram.com/eleletech/'
                      target='_blank'
                    >
                      <img
                        src='images/insta-dark.svg'
                        alt=''
                      />
                    </a>
                  </div>
                  <div className='w-auto p-1.5'>
                    <a
                      className='flex items-center justify-center w-10 h-10 bg-gray-700 rounded-full'
                      href='https://twitter.com/elele_tech'
                      target='_blank'
                    >
                      <img
                        src='images/twitter-dark.svg'
                        alt=''
                      />
                    </a>
                  </div>
                </div>
                <p
                  className='text-gray-500 text-center font-bold'
                  contentEditable='false'
                >
                  <span>&copy; Copyright. All rights reserved by </span>
                  <a className='text-blue-500 hover:text-blue-600' href='/'>
                    Elele Tech Services
                  </a>
                </p>
                <p
                  className='text-gray-600 text-center font-bold'
                  contentEditable='false'
                >
                  <span>Company Registered in UK and Wales: 14984769</span>
                </p>
                <p
                  className='text-gray-600 text-center font-bold'
                  contentEditable='false'
                >
                  <span>
                    Company Registered in State of California: 202355118927
                  </span>
                </p>
              </div>
            </div>
          </div>
        </section>
      </>
    </React.Fragment >
  );
}

