import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Contact() {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const toggleMenu = () => {
    //console.log(isMenuOpen)
    setIsMenuOpen(!isMenuOpen);
  }
  const closeMenu = () => {
    setIsMenuOpen(false);
  }
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        <section className='pt-6 pb-20 bg-gray-900 overflow-hidden'>
          <div className='container mx-auto px-4'>
            <div className='mb-12'>
              <div className='flex items-center justify-between px-6 py-3.5 bg-gray-800 rounded-full'>
                <div className='w-auto'>
                  <div className='flex flex-wrap items-center'>
                    <div className='w-auto'>
                      <a href='/'>
                        <img
                          src='images/EleleTechLogoText.png'
                          alt='Elele Tech Logo'
                          className='h-12'
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <div className='w-auto'>
                  <div className='flex flex-wrap items-center'>
                    <div className='w-auto hidden lg:block'>
                      <ul className='flex items-center justify-center'>
                        <li className='mr-9'>
                          <a
                            className='inline-block text-sm font-bold text-gray-200 hover:text-gray-300'
                            href='/about'
                          >
                            About
                          </a>
                        </li>
                        <li className='mr-9'>
                          <a
                            className='inline-block text-sm font-bold text-gray-200 hover:text-gray-300'
                            href='/services'
                          >
                            Services
                          </a>
                        </li>
                        <li className='mr-9'>
                          <a
                            className='inline-block text-sm font-bold text-gray-200 hover:text-gray-300'
                            href='/blog'
                          >
                            Blog
                          </a>
                        </li>
                        <li>
                          <a
                            className='inline-block text-sm font-bold text-gray-200 hover:text-gray-300'
                            href='/contact'
                          >
                            Contact
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className='w-auto'>
                  <div className='flex flex-wrap items-center'>
                    <div className='w-auto hidden lg:block'>
                      <div className='flex flex-wrap -m-2'>
                        <div className='w-full md:w-auto p-2'>
                          <a
                            className='block w-full px-4 py-2.5 text-sm text-center text-white font-bold bg-gray-800 hover:bg-gray-900 focus:ring-4 focus:ring-gray-500 rounded-full'
                            href='/'
                          >
                            Log In
                          </a>
                        </div>
                        <div className='w-full md:w-auto p-2'>
                          <a
                            className='block w-full px-4 py-2.5 text-sm text-center text-white font-bold bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-200 rounded-full'
                            href='/contact'
                          >
                            Get Started
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className='w-auto lg:hidden'>
                      <a className='inline-block' onClick={toggleMenu} href="#">
                        <svg
                          className='navbar-burger text-blue-500'
                          width={45}
                          height={45}
                          viewBox='0 0 56 56'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <rect
                            width={56}
                            height={56}
                            rx={28}
                            fill='currentColor'
                          />
                          <path
                            d='M37 32H19M37 24H19'
                            stroke='white'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className={isMenuOpen ? 'navbar-menu' : 'hidden navbar-menu fixed top-0 left-0 bottom-0 w-4/6 sm:max-w-xs z-50'}>
                <div className='navbar-backdrop fixed inset-0 bg-gray-800 opacity-80' />
                <nav className='relative z-10 px-9 pt-8 bg-gray-800 h-full overflow-y-auto'>
                  <div className='flex flex-wrap justify-between h-full'>
                    <div className='w-full'>
                      <div className='flex items-center justify-between -m-2'>
                        <div className='w-auto p-2'>
                          <a className='inline-block' href='#'>
                            <img
                              src='images/EleleTechLogoText.png'
                              alt='Elele Tech Logo'
                            />
                          </a>
                        </div>
                        <div className='w-auto p-2'>
                          <a className='inline-block navbar-burger' href='#' onClick={closeMenu}>
                            <svg
                              width={24}
                              height={24}
                              viewBox='0 0 24 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M6 18L18 6M6 6L18 18'
                                stroke='#ffffff'
                                strokeWidth={2}
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                            </svg>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className='flex flex-col justify-center py-8 w-full'>
                      <ul>
                        <li className='mb-9'>
                          <a
                            className='inline-block text-sm font-bold text-gray-200 hover:text-gray-300'
                            href='/about'
                          >
                            About
                          </a>
                        </li>
                        <li className='mb-9'>
                          <a
                            className='inline-block text-sm font-bold text-gray-200 hover:text-gray-300'
                            href='/services'
                          >
                            Services
                          </a>
                        </li>
                        <li className='mb-9'>
                          <a
                            className='inline-block text-sm font-bold text-gray-200 hover:text-gray-300'
                            href='/blog'
                          >
                            Blog
                          </a>
                        </li>
                        <li>
                          <a
                            className='inline-block text-sm font-bold text-gray-200 hover:text-gray-300'
                            href='/contact'
                          >
                            Contact
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className='flex flex-col justify-end w-full pb-8'>
                      <div className='flex flex-wrap -m-2'>
                        <div className='w-full p-2'>
                          <a
                            className='block w-full px-4 py-2.5 text-sm text-center text-white font-bold bg-gray-800 hover:bg-gray-900 focus:ring-4 focus:ring-gray-500 border border-gray-700 rounded-full'
                            href='/'
                          >
                            Log In
                          </a>
                        </div>
                        <div className='w-full p-2'>
                          <a
                            className='block w-full px-4 py-2.5 text-sm text-center text-white font-bold bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-200 rounded-full'
                            href='/contact'
                          >
                            Get Started
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </nav>
              </div>
            </div>
            <div
              className='px-8 py-20 bg-gray-800 overflow-hidden rounded-3xl'
              style={{
                backgroundImage:
                  'url("images/mountain.jpg")',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
              }}
            >
              <div className='md:max-w-2xl text-center mx-auto'>
                <span className='inline-block mb-3 text-sm text-blue-500 font-bold uppercase tracking-widest'>
                  GET IN TOUCH
                </span>
                <h1 className='font-heading mb-6 text-5xl lg:text-6xl text-white font-black tracking-tight'>
                  <span>Join our newsletter for&nbsp;</span>
                  <span className='text-transparent bg-clip-text bg-gradient-orange-light'>
                    latest
                  </span>
                  <span> tips and guides.</span>
                </h1>
                <p className='mb-8 text-xl text-gray-500 font-bold'>
                  Enter your email address below.
                </p>
                <div className='max-w-lg mx-auto'>
                  <div className='flex flex-wrap -m-2'>
                    <div className='w-full md:flex-1 p-2'>
                      <form method="post" action="https://urban-pelican.pikapod.net/subscription/form">
                        <input
                          className='px-6 py-3.5 w-full text-lg text-gray-200 font-bold bg-gray-700 outline-none focus:ring-4 focus:ring-blue-200 placeholder-gray-200 rounded-full'
                          id='headerDarkInput5-1'
                          type='email'
                          name="email"
                          placeholder='Email address'
                        />
                        <input type="hidden" id="ae218" name="l" checked value="ae218fd8-ae7f-46fb-bccd-82322abfff02" />
                        <div className='w-full md:w-auto p-2'>
                          <div className='flex flex-wrap justify-center -m-2'>
                            <div className='w-full md:w-auto p-2'>
                              <input
                                className='block w-full px-8 py-3.5 text-lg text-center text-white font-bold bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-200 rounded-full'
                                value="Subscribe"
                                type="submit"
                              />
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </section>
        <section className='py-10 bg-gray-900 overflow-hidden'>
          <div className='container mx-auto px-4'>
            <div className='py-16 px-8 bg-gray-800 overflow-hidden border border-gray-800 rounded-3xl'>
              <div className='md:max-w-7xl mx-auto'>
                <div className='md:max-w-xl mb-10'>
                  <span className='inline-block mb-5 text-sm text-blue-500 font-bold uppercase tracking-widest'>
                    Frequently asked questions
                  </span>
                  <h2 className='font-heading text-4xl md:text-5xl lg:text-6xl text-white font-black tracking-tight'>
                    Got questions? We’re here to help!
                  </h2>
                </div>
                <div className='flex flex-wrap -m-3'>
                  <div className='w-full p-3'>
                    <div className='p-10 bg-gray-900 rounded-3xl'>
                      <div className='flex flex-wrap -m-2'>
                        <div className='w-full md:w-1/2 p-2'>
                          <h3 className='font-heading text-xl text-white font-black'>
                            How does pricing work?
                          </h3>
                        </div>
                        <div className='w-full md:w-1/2 p-2'>
                          <p className='text-gray-400 font-bold'>
                            We try and make our prices as cheap and affordable
                            as possible. We need to pay our staff and often need
                            to buy comopnents (included in price) from our
                            partners.&nbsp;
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='w-full p-3'>
                    <div className='p-10 bg-gray-900 rounded-3xl'>
                      <div className='flex flex-wrap -m-2'>
                        <div className='w-full md:w-1/2 p-2'>
                          <h3 className='font-heading text-xl text-white font-black'>
                            Do you offer discounts?
                          </h3>
                        </div>
                        <div className='w-full md:w-1/2 p-2'>
                          <p className='text-gray-400 font-bold'>
                            While we're still in our early stages of growth, we don't offer discounts. However, we do offer a referral program. If you refer a friend, you'll get 10% off your next purchase.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='w-full p-3'>
                    <div className='p-10 bg-gray-900 rounded-3xl'>
                      <div className='flex flex-wrap -m-2'>
                        <div className='w-full md:w-1/2 p-2'>
                          <h3 className='font-heading text-xl text-white font-black'>
                            Do you guys offer packages?
                          </h3>
                        </div>
                        <div className='w-full md:w-1/2 p-2'>
                          <p className='text-gray-400 font-bold'>
                            We do have some packages available for businesses. Please contact us for more information.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-10 bg-gray-900 overflow-hidden'>
          <div className='container mx-auto px-4'>
            <div className='py-16 px-8 bg-gray-800 overflow-hidden rounded-3xl'>
              <div className='max-w-5xl mx-auto'>
                <div className='mb-10 md:max-w-md mx-auto text-center'>
                  <span className='inline-block mb-4 text-sm text-blue-500 font-bold uppercase tracking-widest'>
                    Contact Us
                  </span>
                  <h2 className='font-heading mb-6 text-4xl md:text-5xl lg:text-6xl text-white font-black tracking-tight'>
                    Get connected
                  </h2>
                  <p className='text-gray-400 font-bold'>
                    We're here to assist you. If you have any questions,
                    inquiries, or would like to discuss potential
                    collaborations, please don't hesitate to reach out. Your
                    feedback and inquiries are important to us, and we look
                    forward to hearing from you.
                  </p>
                </div>
                <form className='p-10 bg-gray-900 rounded-3xl' action="https://submit-form.com/0RDDFBSME">
                  <div className='flex flex-wrap -m-5 mb-1'>
                    <div className='w-full md:w-1/2 p-5'>
                      <label
                        className='block mb-2 text-sm text-gray-500 font-bold'
                        htmlFor='contactDarkInput2-1'
                        for="name"
                      >
                        Name
                      </label>
                      <input
                        className='appearance-none px-6 py-3.5 w-full text-lg text-gray-500 font-bold bg-gray-800 placeholder-gray-500 outline-none focus:ring-4 focus:ring-blue-200 rounded-full'
                        id='contactDarkInput2-1'
                        type='text'
                        placeholder='Enter your name'
                        name="name"
                        required=""
                      />
                    </div>
                    <div className='w-full md:w-1/2 p-5'>
                      <label
                        className='block mb-2 text-sm text-gray-500 font-bold'
                        htmlFor='contactDarkInput2-2'
                        for="email"
                      >
                        Emaill address
                      </label>
                      <input
                        className='appearance-none px-6 py-3.5 w-full text-lg text-gray-500 font-bold bg-gray-800 placeholder-gray-500 outline-none focus:ring-4 focus:ring-blue-200 rounded-full'
                        id='contactDarkInput2-2'
                        type='email'
                        placeholder='Email address'
                        name="email"
                        required=""
                      />
                    </div>
                  </div>
                  <div className='flex flex-wrap -m-3.5'>
                    <div className='w-full p-3.5'>
                      <label
                        className='block mb-2 text-sm text-gray-500 font-bold'
                        htmlFor='contactDarkInput2-5'
                        for="message"
                      >
                        Message
                      </label>
                      <textarea
                        className='appearance-none px-6 py-5 w-full text-lg text-gray-500 font-bold bg-gray-800 placeholder-gray-500 outline-none focus:ring-4 focus:ring-blue-200 rounded-3xl'
                        id='contactDarkInput2-5'
                        type='text'
                        rows={8}
                        placeholder='Enter your message'
                        defaultValue={''}
                        name="message"
                        required=""
                      />
                    </div>
                    <div className='w-full p-3.5'>
                      <div className='flex flex-wrap items-center'>
                        <div className='w-full p-2'>
                          <div className='flex flex-wrap md:justify-end -m-2'>
                            <div className='w-full md:w-auto p-2'>
                              <button
                                className='block w-full px-8 md:px-16 py-3.5 text-lg text-center text-white font-bold bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-200 rounded-full'
                                type="submit"
                              >
                                Send Message
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                <div className='flex flex-wrap -m-4'>
                  <div className='w-full md:w-1/3 p-4'>
                    <div className='p-10 text-center h-full rounded-3xl'>
                      <div className='flex items-center justify-center mb-6 w-12 h-12 mx-auto bg-gray-900 rounded-xl'>
                        <svg
                          width={24}
                          height={24}
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M14 16C14 17.77 13.23 19.37 12 20.46C10.94 21.42 9.54 22 8 22C4.69 22 2 19.31 2 16C2 13.9753 3.01397 12.1814 4.5554 11.0973C4.80358 10.9228 5.1393 11.0422 5.27324 11.3144C6.21715 13.2332 7.95419 14.6699 10.02 15.23C10.65 15.41 11.31 15.5 12 15.5C12.4872 15.5 12.9539 15.4538 13.4074 15.3687C13.6958 15.3146 13.9828 15.4995 13.9955 15.7925C13.9985 15.862 14 15.9314 14 16Z'
                            fill='#3B82F6'
                          />
                          <path
                            d='M18 8C18 8.78 17.85 9.53 17.58 10.21C16.89 11.95 15.41 13.29 13.58 13.79C13.08 13.93 12.55 14 12 14C11.45 14 10.92 13.93 10.42 13.79C8.59 13.29 7.11 11.95 6.42 10.21C6.15 9.53 6 8.78 6 8C6 4.69 8.69 2 12 2C15.31 2 18 4.69 18 8Z'
                            fill='#3B82F6'
                          />
                          <path
                            d='M22 16C22 19.31 19.31 22 16 22C15.2555 22 14.5393 21.8643 13.8811 21.6141C13.5624 21.4929 13.503 21.0851 13.7248 20.8262C14.8668 19.4938 15.5 17.786 15.5 16C15.5 15.66 15.47 15.32 15.42 15V15C15.3902 14.8155 15.4844 14.6342 15.6478 14.5437C16.9719 13.8107 18.0532 12.6875 18.727 11.3153C18.8609 11.0427 19.1968 10.923 19.4452 11.0977C20.9863 12.1818 22 13.9755 22 16Z'
                            fill='#3B82F6'
                          />
                        </svg>
                      </div>
                      <h3 className='font-heading mb-2 text-xl text-white font-black'>
                        Send Email
                      </h3>
                      <p className='text-gray-400 font-bold'>
                        support@elele.zip
                      </p>
                      <p className='text-gray-400 font-bold'>help@elele.tech</p>
                    </div>
                  </div>
                  <div className='w-full md:w-1/3 p-4'>
                    <div className='p-10 text-center h-full rounded-3xl'>
                      <div className='flex items-center justify-center mb-6 w-12 h-12 mx-auto bg-gray-900 rounded-xl'>
                        <svg
                          width={24}
                          height={24}
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M14 16C14 17.77 13.23 19.37 12 20.46C10.94 21.42 9.54 22 8 22C4.69 22 2 19.31 2 16C2 13.9753 3.01397 12.1814 4.5554 11.0973C4.80358 10.9228 5.1393 11.0422 5.27324 11.3144C6.21715 13.2332 7.95419 14.6699 10.02 15.23C10.65 15.41 11.31 15.5 12 15.5C12.4872 15.5 12.9539 15.4538 13.4074 15.3687C13.6958 15.3146 13.9828 15.4995 13.9955 15.7925C13.9985 15.862 14 15.9314 14 16Z'
                            fill='#3B82F6'
                          />
                          <path
                            d='M18 8C18 8.78 17.85 9.53 17.58 10.21C16.89 11.95 15.41 13.29 13.58 13.79C13.08 13.93 12.55 14 12 14C11.45 14 10.92 13.93 10.42 13.79C8.59 13.29 7.11 11.95 6.42 10.21C6.15 9.53 6 8.78 6 8C6 4.69 8.69 2 12 2C15.31 2 18 4.69 18 8Z'
                            fill='#3B82F6'
                          />
                          <path
                            d='M22 16C22 19.31 19.31 22 16 22C15.2555 22 14.5393 21.8643 13.8811 21.6141C13.5624 21.4929 13.503 21.0851 13.7248 20.8262C14.8668 19.4938 15.5 17.786 15.5 16C15.5 15.66 15.47 15.32 15.42 15V15C15.3902 14.8155 15.4844 14.6342 15.6478 14.5437C16.9719 13.8107 18.0532 12.6875 18.727 11.3153C18.8609 11.0427 19.1968 10.923 19.4452 11.0977C20.9863 12.1818 22 13.9755 22 16Z'
                            fill='#3B82F6'
                          />
                        </svg>
                      </div>
                      <h3 className='font-heading mb-2 text-xl text-white font-black'>
                        Call Us
                      </h3>
                      <p className='text-gray-400 font-bold'>Coming Soon</p>
                    </div>
                  </div>
                  <div className='w-full md:w-1/3 p-4'>
                    <div className='p-10 text-center h-full rounded-3xl'>
                      <div className='flex items-center justify-center mb-6 w-12 h-12 mx-auto bg-gray-900 rounded-xl'>
                        <svg
                          width={24}
                          height={24}
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M14 16C14 17.77 13.23 19.37 12 20.46C10.94 21.42 9.54 22 8 22C4.69 22 2 19.31 2 16C2 13.9753 3.01397 12.1814 4.5554 11.0973C4.80358 10.9228 5.1393 11.0422 5.27324 11.3144C6.21715 13.2332 7.95419 14.6699 10.02 15.23C10.65 15.41 11.31 15.5 12 15.5C12.4872 15.5 12.9539 15.4538 13.4074 15.3687C13.6958 15.3146 13.9828 15.4995 13.9955 15.7925C13.9985 15.862 14 15.9314 14 16Z'
                            fill='#3B82F6'
                          />
                          <path
                            d='M18 8C18 8.78 17.85 9.53 17.58 10.21C16.89 11.95 15.41 13.29 13.58 13.79C13.08 13.93 12.55 14 12 14C11.45 14 10.92 13.93 10.42 13.79C8.59 13.29 7.11 11.95 6.42 10.21C6.15 9.53 6 8.78 6 8C6 4.69 8.69 2 12 2C15.31 2 18 4.69 18 8Z'
                            fill='#3B82F6'
                          />
                          <path
                            d='M22 16C22 19.31 19.31 22 16 22C15.2555 22 14.5393 21.8643 13.8811 21.6141C13.5624 21.4929 13.503 21.0851 13.7248 20.8262C14.8668 19.4938 15.5 17.786 15.5 16C15.5 15.66 15.47 15.32 15.42 15V15C15.3902 14.8155 15.4844 14.6342 15.6478 14.5437C16.9719 13.8107 18.0532 12.6875 18.727 11.3153C18.8609 11.0427 19.1968 10.923 19.4452 11.0977C20.9863 12.1818 22 13.9755 22 16Z'
                            fill='#3B82F6'
                          />
                        </svg>
                      </div>
                      <h3 className='font-heading mb-2 text-xl text-white font-black'>
                        Address
                      </h3>
                      <p className='text-gray-400 font-bold'>
                        128 City Road, London,
                      </p>
                      <p className='text-gray-400 font-bold'>
                        United Kingdom, EC1V 2NX
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-10 bg-gray-900 overflow-hidden'>
          <div className='container mx-auto px-4'>
            <div className='py-16 px-8 bg-gray-800 rounded-3xl'>
              <div className='flex flex-col justify-centers'>
                <a className='inline-block max-w-max mx-auto mb-10' href='/'>
                  <img
                    src='images/EleleTechLogoText.png'
                    alt=''
                    className='h-16'
                  />
                </a>
                <ul className='-m-6 mb-7 text-center'>
                  <li className='inline-flex p-6'>
                    <a
                      className='inline-block text-gray-500 hover:text-gray-600 font-bold'
                      href='/services'
                    >
                      Services
                    </a>
                  </li>
                  <li className='inline-flex p-6'>
                    <a
                      className='inline-block text-gray-500 hover:text-gray-600 font-bold'
                      href='/about'
                    >
                      About Us
                    </a>
                  </li>
                  <li className='inline-flex p-6'>
                    <a
                      className='inline-block text-gray-500 hover:text-gray-600 font-bold'
                      href='/careers'
                    >
                      Careers
                    </a>
                  </li>
                  <li className='inline-flex p-6'>
                    <a
                      className='inline-block text-gray-500 hover:text-gray-600 font-bold'
                      href='/contact'
                    >
                      Contact Us
                    </a>
                  </li>
                </ul>
                <div className='flex flex-wrap justify-center -m-1.5 mb-10'>
                  <div className='w-auto p-1.5'>
                    <a
                      className='flex items-center justify-center w-10 h-10 bg-gray-700 rounded-full'
                      href='https://instagram.com/eleletech'
                    >
                      <img
                        src='images/insta-dark.svg'
                        alt=''
                      />
                    </a>
                  </div>
                  <div className='w-auto p-1.5'>
                    <a
                      className='flex items-center justify-center w-10 h-10 bg-gray-700 rounded-full'
                      href='https://twitter.com/elele_tech'
                    >
                      <img
                        src='images/twitter-dark.svg'
                        alt=''
                      />
                    </a>
                  </div>
                  <div className='w-auto p-1.5' />
                </div>
                <p
                  className='text-gray-500 text-center font-bold'
                  contentEditable='false'
                >
                  <span>&copy; Copyright. All rights reserved by </span>
                  <a className='text-blue-500 hover:text-blue-600' href='/'>
                    Elele Tech Services
                  </a>
                </p>
                <p
                  className='text-gray-600 text-center font-bold'
                  contentEditable='false'
                >
                  <span>Company Registered in UK and Wales: 14984769</span>
                </p>
                <p
                  className='text-gray-600 text-center font-bold'
                  contentEditable='false'
                >
                  <span>
                    Company Registered in State of California: 202355118927
                  </span>
                </p>
              </div>
            </div>
          </div>
        </section>
      </>
    </React.Fragment >
  );
}

